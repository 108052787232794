import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Settings from "./pages/Settings";
import Home from "./pages/Home";
import { Gobj } from "./components/Form";
import "./App.css";

export const Mycontext = createContext({
  value: {
    p10kl: 0,
    p14kl: 0,
    p18kl: 0,
    p10km: 0,
    p14km: 0,
    p18km: 0,
    p10kd: 0,
    p14kd: 0,
    p18kd: 0,
    currency: "",
    type: "Input your values",
  },
  inc: (x: Gobj) => {},
});

function App() {
  const inc = (x: Gobj) => {
    setState({ ...state, value: x });
  };

  const [state, setState] = useState({
    value: {
      p10kl: 0,
      p14kl: 0,
      p18kl: 0,
      p10km: 0,
      p14km: 0,
      p18km: 0,
      p10kd: 0,
      p14kd: 0,
      p18kd: 0,
      currency: "",
      type: "Input your values",
    },
    inc: inc,
  });

  return (
    <Mycontext.Provider value={state}>
      <Router>
        <Switch>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <div className="App"></div>
        </Switch>
      </Router>
    </Mycontext.Provider>
  );
}

export default App;

import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Mycontext } from "../App";

const Dics = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
`;

type ColumnsType =
  | {
      key: string;
      blank: string;
      lsim: string;
      mois: string;
      diamond: string;
    }
  | any;

export const commas = (x: number | undefined) => {
  let result = "";
  if (x === undefined) {
    result = "";
  } else {
    result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return result;
};

function Display() {
  const Context = useContext(Mycontext);
  useEffect(() => {}, [Context]);

  const columns: ColumnsType = [
    {
      title: "",
      dataIndex: "blank",
      align: "center",
      className: "tb2",
    },
    {
      title: "Lab Simulated",
      dataIndex: "lsim",
      align: "center",
      className: "tb1",
    },
    {
      title: "Moissanite",
      dataIndex: "mois",
      align: "center",
      className: "tb1",
    },
    {
      title: "Diamond",
      dataIndex: "diamond",
      align: "center",
      className: "tb1",
    },
  ];

  const data = [
    {
      key: "1",
      blank: "10k",
      lsim: commas(Context.value.p10kl),
      mois: commas(Context.value.p10km),
      diamond: commas(Context.value.p10kd),
    },
    {
      key: "2",
      blank: "14k",
      lsim: commas(Context.value.p14kl),
      mois: commas(Context.value.p14km),
      diamond: commas(Context.value.p14kd),
    },
    {
      key: "3",
      blank: "18k",
      lsim: commas(Context.value.p18kl),
      mois: commas(Context.value.p18km),
      diamond: commas(Context.value.p18kd),
    },
  ];

  return (
    <div style={{ padding: "10px", fontSize: "25px" }}>
      <Table
        pagination={false}
        className="tt32"
        columns={columns}
        dataSource={data}
        bordered
        title={() => (
          <Dics>
            {" "}
            {Context.value.type === "engagement"
              ? "Engagement Ring"
              : null}{" "}
            {Context.value.type === "bridal" ? "Bridal Set" : null}{" "}
            {Context.value.type === "complete" ? "Complete Set" : null}
            <span>
              {" "}
              {Context.value.currency === "naira" ? "(NGN)" : null}
              {Context.value.currency === "cedis" ? "(Cedis)" : null}
              {Context.value.currency === "cad" ? "(CAD)" : null}{" "}
              {Context.value.currency === "usd" ? "(USD)" : null}{" "}
            </span>
          </Dics>
        )}
      />
    </div>
  );
}

export default Display;

import React from "react";
import Navbar from "../components/Navbar";
import Form from "../components/Form";
import Display from "../components/Display";
import Footer from "../components/Footer";
import styled from "styled-components";
import Calc from "../components/Calc";

const Main = styled.div`
  width: 80%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
`;
const Divi = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  /* width: 100vw; */

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Div2 = styled.div`
  position: relative;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  display: flex;
  justify-content: center;
`;

const Col1 = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  return (
    <div>
      <Navbar />
      <Main>
        <Div2>
          <Calc />
        </Div2>
        <Divi>
          <Col1>
            {" "}
            <Form />{" "}
          </Col1>
          <Col1>
            {" "}
            <Display />{" "}
          </Col1>
        </Divi>
        <Footer />
      </Main>
    </div>
  );
};

export default Home;

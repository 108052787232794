import styled from "styled-components";
export const Buttonx = styled.button`
  padding: 3px 10px;
  border: 1px solid grey;
  border-radius: 10px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ead2ac;
  color: black;
  font-weight: bold;
  box-shadow: 2px 2px;
  cursor: pointer;
  :hover {
    background-color: #f3e5ce;
  }
`;
export const InputGroupx = styled.div`
  position: relative;
`;

export const Inputx = styled.input`
  padding: 10px;
  border: 1px solid #ead2ac;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 10px;
  background-color: white;
  z-index: 1;
  position: relative;
  margin-right: 10px;
`;
export const Labelx = styled.label`
  font-size: 10px;
  position: absolute;
  top: -7px;
  left: 25px;
  padding: 0px;
  background-color: white;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 10px;
  z-index: 2;
`;
export const Selectx = styled.select`
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: transparent;
  border: 1px solid #ead2ac;
`;

export const Optionx = styled.span`
  padding: 10px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import Navbar from '../components/Navbar'
import {Btn, Formborder, Formdetails} from '../components/Form'
import styled from 'styled-components'
import firebase from '../firebase/firebase'
import {Buttonx, InputGroupx, Inputx, Labelx} from '../UI'
import Footer from '../components/Footer'
import bcrypt from 'bcryptjs'

const Main = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    z-index: 10;
`

const Flexg = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
`

const Pop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 120px;
`
const Pop1 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
    color: red;
    font-weight: bold;
`

const Modal = styled.div`
    position: relative;
`

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    background-color: #f7f4f4;
    opacity: 1;
`
const ModalInput = styled.div`
    position: relative;
    z-index: 13;
`

interface Inter {
    k10p: number
    k14p: number
    k18p: number
    ngn2Usd: number | string
    ceddi2Usd: number | string
    cad2Usd: number | string
}

const val: any = {
    k10p: 0,
    k14p: 0,
    k18p: 0,
    ngn2Usd: 0,
    ceddi2Usd: 0,
    cad2Usd: 0,
}

function Settings() {
    const db = firebase.firestore()
    const [k, setk] = useState<Inter>(val)
    const [open, setOpen] = useState(true)
    const [pin, setPin] = useState('')
    const [err, setErr] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const pp = '$2a$10$z7zgjDxuGE.CcZHNgaUZmOVShNbYa5y9mQoxbDYYPD9Lfc4Vf7ek.'

    useEffect(() => {
        getValues()
    }, [])

    const bbcr = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        bcrypt
            .compare(pin, pp)
            .then((res) => {
                if (res) {
                    setOpen(false)
                } else {
                    setOpen(true)
                    setErr(true)
                    setErrMsg('Wrong pin!')
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getValues = async () => {
        try {
            await db
                .collection('settings')
                .doc('settings')
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        let tt = doc.data() as unknown as Inter

                        setk(tt)
                    } else {
                        console.log('No doc present')
                    }
                })
                .then(() => {})
        } catch (e) {
            if (e) {
                console.log(e)
            }
        }
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let id = e.target.id

        let value = e.target.value ?? 0

        switch (id) {
            case '10k':
                setk({ ...k, k10p: parseInt(value) ?? 0 })
                break
            case '14k':
                setk({ ...k, k14p: parseInt(value) ?? 0 })
                break
            case '18k':
                setk({ ...k, k18p: parseInt(value) ?? 0 })
                break
            case 'ngn2Usd':
                setk({ ...k, ngn2Usd: value })
                break
            case 'ceddi2Usd':
                setk({ ...k, ceddi2Usd: value })
                break
            case 'cad2Usd':
                setk({ ...k, cad2Usd: value })
                break
            default:
        }
    }

    const submitH = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        if (k) {
            console.log({ k })
            await db
                .collection('settings')
                .doc('settings')
                .set(k)
                .then((doc: any) => {
                    console.log('Document written')
                })
                .catch((e) => {
                    if (e) {
                        console.log(e)
                    }
                })
        }
    }

    if (!k) {
        return <div> loading... </div>
    }

    return (
        <div>
            <Navbar />
            {open && (
                <Modal>
                    <Backdrop></Backdrop>
                    <ModalInput>
                        {err && <Pop1> {errMsg} </Pop1>}
                        <Pop>
                            <form onSubmit={bbcr}>
                                <InputGroupx>
                                    <Labelx> Input your PIN</Labelx>
                                    <Inputx
                                        type="password"
                                        onChange={(e) => {
                                            setPin(e.target.value)
                                        }}
                                        value={pin}
                                    />
                                    <Buttonx> Submit </Buttonx>
                                </InputGroupx>
                            </form>
                        </Pop>
                    </ModalInput>
                </Modal>
            )}

            <Main>
                <Formborder>
                    <Formdetails>
                        {' '}
                        <div> Settings</div>{' '}
                    </Formdetails>

                    <form>
                        <Flexg>
                            {' '}
                            <InputGroupx>
                                <Labelx> 10k Price </Labelx>
                                <Inputx
                                    type="number"
                                    id="10k"
                                    placeholder="10k Price"
                                    value={k.k10p}
                                    onChange={changeHandler}
                                />{' '}
                            </InputGroupx>
                            <InputGroupx>
                                <Labelx> 14k Price </Labelx>
                                <Inputx
                                    type="number"
                                    id="14k"
                                    placeholder="14k Price"
                                    value={k.k14p}
                                    onChange={changeHandler}
                                />{' '}
                            </InputGroupx>
                        </Flexg>
                        <Flexg>
                            <InputGroupx>
                                <Labelx> 18k Price </Labelx>
                                <Inputx
                                    type="number"
                                    id="18k"
                                    placeholder="18k Price"
                                    value={k.k18p}
                                    onChange={changeHandler}
                                />{' '}
                            </InputGroupx>{' '}
                            <InputGroupx>
                                <Labelx> NGN rate to USD </Labelx>
                                <Inputx
                                    id="ngn2Usd"
                                    placeholder="NGN Exchange Rate to USD"
                                    value={k.ngn2Usd}
                                    onChange={changeHandler}
                                />{' '}
                            </InputGroupx>
                            <InputGroupx>
                                <Labelx> Ceddi rate to USD </Labelx>
                                <Inputx
                                    id="ceddi2Usd"
                                    placeholder="Ceddi Exchange Rate to USD"
                                    value={k.ceddi2Usd}
                                    onChange={changeHandler}
                                />{' '}
                            </InputGroupx>
                            <InputGroupx>
                                <Labelx> CAD rate to USD </Labelx>
                                <Inputx
                                    id="cad2Usd"
                                    placeholder="Cad Exchange Rate to USD"
                                    value={k.cad2Usd}
                                    onChange={changeHandler}
                                />{' '}
                            </InputGroupx>
                        </Flexg>

                        <Btn>
                            <Buttonx onClick={submitH}>Save Settings </Buttonx>
                        </Btn>
                    </form>
                </Formborder>
            </Main>
            <Footer />
        </div>
    )
}

export default Settings

import React  from 'react'
import styled from 'styled-components' 
import {useHistory} from 'react-router' 
import {FaCalculator} from 'react-icons/fa'
import {MdSettings} from 'react-icons/md'


const DIV =  styled.div`
margin-top: 2px;
margin-bottom: 10px;
background-color: #ead2ac;
padding: 10px;
`

const Flexd = styled.div`
display: flex; 
justify-content: space-between;
`

const MenuItem = styled.div `
margin-left: 20px;
margin-right: 20px;
cursor: pointer;
font-family: 'Ubuntu', sans-serif;
font-weight: 700;
`

function Navbar() { 

  const history = useHistory()
    const settingsx = () => { 
        history.push("/settings")
        } 

        const home = () => {
            history.push("/")
        }
     
    return (
        <DIV>
            <Flexd> 
                <MenuItem onClick = {home}> Gold Calculator <span style = {{position:"relative",top:"2px",marginLeft:"5px"}}> <FaCalculator/>  </span></MenuItem>
                <Flexd> 
                    <MenuItem onClick = {settingsx}> Settings <span style = {{position:"relative",top:"2px",marginLeft:"1px"}}> <MdSettings/>  </span>  </MenuItem> 
                </Flexd>
            
            </Flexd>
        </DIV>
    )
}

export default Navbar

import React from 'react'
import styled from 'styled-components'

const DD = styled.div`
text-align: center;
`

function Footer() {
    return (
        <DD>
             ©{new Date().getFullYear()} Boolean
        </DD>
    )
}

export default Footer

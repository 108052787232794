/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import firebase from '../firebase/firebase'
import styled from 'styled-components'
import {Buttonx, InputGroupx, Inputx, Labelx} from '../UI'
import {GiComputerFan} from 'react-icons/gi'
import {Select} from 'antd'
import 'antd/dist/antd.css'
import {Mycontext} from '../App'

const { Option } = Select

export const Flexi = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    @media (max-width: 500px) {
        justify-content: center;
    }
`

const Divax = styled.div`
    margin: 10px;
    display: flex;
    justify-content: space-between;
`

export const Btn = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
`
export const Dibb = styled.div`
    display: flex;
    justify-content: center;
`
export const Formborder = styled.div`
    border: 1px solid #bae1f8;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    z-index: 7;
    background-color: #f3f6f6;
    margin: 5px;
    @media (max-width: 500px) {
        margin-left: 10px;
        margin-right: 10px;
    }
`

export const Formdetails = styled.div`
    position: absolute;
    left: 145px;
    top: -13px;
    z-index: 5;
    background-color: white;
    text-align: center;
`

export interface Gobj {
    p10kl: number
    p14kl: number
    p18kl: number
    p10km: number
    p14km: number
    p18km: number
    p10kd: number
    p14kd: number
    p18kd: number
    type: string
    currency: string
}

export type SelectValue = string

function Form() {
    const db = firebase.firestore()
    const Context = useContext(Mycontext)
    const [cdiamond, setCdiamond] = useState<number>(0)
    const [odiamond, setOdiamond] = useState<number>(0)
    const [cmoissanite, setCmoissanite] = useState<number>(0)
    const [omoissanite, setOmoissanite] = useState<number>(0)
    const [weight, setweight] = useState<any>(0)
    const [bigstones, setBigstones] = useState<number>(0)
    const [ostones, setOstones] = useState<number>(0)
    const [type, setType] = useState<any>('engagement')
    const [currency, setCurrency] = useState<any>('naira')
    const [params, setParams] = useState<any>({})
    const [value, setValue] = useState<Gobj>({
        p10kl: 0,
        p14kl: 0,
        p18kl: 0,
        p10km: 0,
        p14km: 0,
        p18km: 0,
        p10kd: 0,
        p14kd: 0,
        p18kd: 0,
        type: 'Input your values',
        currency: '',
    })

    useEffect(() => {
        getValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        Context.inc(value)
    }, [value])

    const changeH = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target
        switch (id) {
            case 'cdiamond':
                isNaN(parseInt(value))
                    ? setCdiamond(0)
                    : setCdiamond(parseInt(value))
                break
            case 'odiamond':
                isNaN(parseInt(value))
                    ? setOdiamond(0)
                    : setOdiamond(parseInt(value))
                break
            case 'cmoissanite':
                isNaN(parseInt(value))
                    ? setCmoissanite(0)
                    : setCmoissanite(parseInt(value.toString()))
                break
            case 'omoissanite':
                isNaN(parseInt(value))
                    ? setOmoissanite(0)
                    : setOmoissanite(parseInt(value))
                break
            case 'weight':
                setweight(value)
                break
            case 'bigstone':
                setBigstones(parseInt(value))
                break
            case 'ostones':
                setOstones(parseInt(value))
                break
            default:
                break
        }
    }

    const getValues = async () => {
        try {
            await db
                .collection('settings')
                .doc('settings')
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        let tt = doc.data()

                        setParams(tt)
                    } else {
                        console.log('No doc present')
                    }
                })
                .then(() => {})
        } catch (e) {
            if (e) {
                console.log(e)
            }
        }
    }

    const sumbitH = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        let typeofring = 1
        if (type === 'engagement') typeofring = 1
        if (type === 'bridal') typeofring = 2
        if (type === 'complete') typeofring = 3

        let p10kl =
            (weight * params.k10p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2) *
            2
        let p14kl =
            (weight * params.k14p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2) *
            2
        let p18kl =
            (weight * params.k18p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2) *
            2
        let p10km =
            (weight * params.k10p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2 +
                (cmoissanite || 0) +
                (omoissanite || 0)) *
            2
        let p14km =
            (weight * params.k14p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2 +
                (cmoissanite || 0) +
                (omoissanite || 0)) *
            2
        let p18km =
            (weight * params.k18p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2 +
                (cmoissanite || 0) +
                (omoissanite || 0)) *
            2
        let p10kd =
            (weight * params.k10p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2 +
                (cdiamond || 0) +
                (odiamond || 0)) *
            1.8
        let p14kd =
            (weight * params.k14p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2 +
                (cdiamond || 0) +
                (odiamond || 0)) *
            1.8
        let p18kd =
            (weight * params.k18p +
                typeofring * 30 +
                typeofring * 25 +
                (bigstones || 0) * 20 +
                (ostones || 0) * 2 +
                (cdiamond || 0) +
                (odiamond || 0)) *
            1.8

        let result: any = {
            p10kl: p10kl,
            p14kl: p14kl,
            p18kl: p18kl,
            p10km: p10km,
            p14km: p14km,
            p18km: p18km,
            p10kd: p10kd,
            p14kd: p14kd,
            p18kd: p18kd,
        }

        if (currency === 'naira') {
            result = {
                p10kl: Math.round((p10kl * params.ngn2Usd) / 100) * 100,
                p14kl: Math.round((p14kl * params.ngn2Usd) / 100) * 100,
                p18kl: Math.round((p18kl * params.ngn2Usd) / 100) * 100,
                p10km: Math.round((p10km * params.ngn2Usd) / 100) * 100,
                p14km: Math.round((p14km * params.ngn2Usd) / 100) * 100,
                p18km: Math.round((p18km * params.ngn2Usd) / 100) * 100,
                p10kd: Math.round((p10kd * params.ngn2Usd) / 100) * 100,
                p14kd: Math.round((p14kd * params.ngn2Usd) / 100) * 100,
                p18kd: Math.round((p18kd * params.ngn2Usd) / 100) * 100,
                type: type,
                currency: currency,
            }
        }
        if (currency === 'cedis') {
            result = {
                p10kl: Math.round((p10kl * params.ceddi2Usd) / 100) * 100,
                p14kl: Math.round((p14kl * params.ceddi2Usd) / 100) * 100,
                p18kl: Math.round((p18kl * params.ceddi2Usd) / 100) * 100,
                p10km: Math.round((p10km * params.ceddi2Usd) / 100) * 100,
                p14km: Math.round((p14km * params.ceddi2Usd) / 100) * 100,
                p18km: Math.round((p18km * params.ceddi2Usd) / 100) * 100,
                p10kd: Math.round((p10kd * params.ceddi2Usd) / 100) * 100,
                p14kd: Math.round((p14kd * params.ceddi2Usd) / 100) * 100,
                p18kd: Math.round((p18kd * params.ceddi2Usd) / 100) * 100,
                type: type,
                currency: currency,
            }
        }
        if (currency === 'usd') {
            result = {
                p10kl: Math.round(p10kl / 10) * 10,
                p14kl: Math.round(p14kl / 10) * 10,
                p18kl: Math.round(p18kl / 10) * 10,
                p10km: Math.round(p10km / 10) * 10,
                p14km: Math.round(p14km / 10) * 10,
                p18km: Math.round(p18km / 10) * 10,
                p10kd: Math.round(p10kd / 10) * 10,
                p14kd: Math.round(p14kd / 10) * 10,
                p18kd: Math.round(p18kd / 10) * 10,
                type: type,
                currency: currency,
            }
        }
        if (currency === 'cad') {
            result = {
                p10kl: Math.round((p10kl * params.cad2Usd) / 100) * 100,
                p14kl: Math.round((p14kl * params.cad2Usd) / 100) * 100,
                p18kl: Math.round((p18kl * params.cad2Usd) / 100) * 100,
                p10km: Math.round((p10km * params.cad2Usd) / 100) * 100,
                p14km: Math.round((p14km * params.cad2Usd) / 100) * 100,
                p18km: Math.round((p18km * params.cad2Usd) / 100) * 100,
                p10kd: Math.round((p10kd * params.cad2Usd) / 100) * 100,
                p14kd: Math.round((p14kd * params.cad2Usd) / 100) * 100,
                p18kd: Math.round((p18kd * params.cad2Usd) / 100) * 100,
                type: type,
                currency: currency,
            }
        }

        setValue(result)

        Context.inc(result)
    }
    const focusx = (e: React.FocusEvent<HTMLInputElement>) => {
        e.currentTarget.select()
    }
    return (
        <div>
            <Formborder>
                <Formdetails>
                    {' '}
                    <div> Form Details</div>{' '}
                </Formdetails>

                <form autoComplete="off">
                    <Divax>
                        {' '}
                        <InputGroupx>
                            {' '}
                            <Labelx>Center Diamond </Labelx>{' '}
                            <Inputx
                                type="number"
                                id="cdiamond"
                                value={cdiamond}
                                onChange={changeH}
                                onFocus={focusx}
                            />{' '}
                        </InputGroupx>{' '}
                        <InputGroupx>
                            {' '}
                            <Labelx>Other Diamond </Labelx>{' '}
                            <Inputx
                                type="number"
                                id="odiamond"
                                value={odiamond}
                                onChange={changeH}
                                onFocus={focusx}
                            />{' '}
                        </InputGroupx>{' '}
                    </Divax>
                    <Divax>
                        {' '}
                        <InputGroupx>
                            {' '}
                            <Labelx>Center Moissanite </Labelx>{' '}
                            <Inputx
                                type="number"
                                id="cmoissanite"
                                value={cmoissanite}
                                onChange={changeH}
                                onFocus={focusx}
                            />
                        </InputGroupx>{' '}
                        <InputGroupx>
                            {' '}
                            <Labelx>Other Moissanite </Labelx>{' '}
                            <Inputx
                                type="number"
                                id="omoissanite"
                                value={omoissanite}
                                onChange={changeH}
                                onFocus={focusx}
                            />{' '}
                        </InputGroupx>{' '}
                    </Divax>
                    <Divax style={{ justifyContent: 'center' }}>
                        {' '}
                        <InputGroupx>
                            {' '}
                            <Labelx>Weight </Labelx>{' '}
                            <Inputx
                                type="number"
                                id="weight"
                                value={weight}
                                onChange={changeH}
                                onFocus={focusx}
                            />{' '}
                        </InputGroupx>{' '}
                    </Divax>
                    <Divax>
                        {' '}
                        <InputGroupx>
                            {' '}
                            <Labelx>Big Stones </Labelx>{' '}
                            <Inputx
                                type="number"
                                id="bigstone"
                                value={bigstones}
                                onChange={changeH}
                                onFocus={focusx}
                            />{' '}
                        </InputGroupx>
                        <InputGroupx>
                            <Labelx>Other Stones </Labelx>{' '}
                            <Inputx
                                type="number"
                                id="ostones"
                                value={ostones}
                                onChange={changeH}
                                onFocus={focusx}
                            />{' '}
                        </InputGroupx>{' '}
                    </Divax>
                    <Flexi>
                        <Select
                            defaultValue="Engagement Ring"
                            onChange={(e) => {
                                setType(e)
                            }}
                            style={{
                                width: 220,
                                border: '1px solid  #ead2ac',
                                fontSize: '15px',
                                fontFamily: ' Arial, Helvetica, sans-serif',
                            }}
                            id="sel1"
                        >
                            <Option
                                value="engagement"
                                id="engagement"
                                style={{ marginBottom: '10px' }}
                            >
                                {' '}
                                Engagement Ring{' '}
                            </Option>
                            <Option value="bridal" id="bridal">
                                {' '}
                                Bridal Set{' '}
                            </Option>
                            <Option value="complete" id="complete">
                                {' '}
                                Complete Wedding Rings
                            </Option>
                        </Select>
                    </Flexi>
                    <Flexi>
                        <Select
                            defaultValue="Naira"
                            style={{
                                marginLeft: '2px',
                                width: 120,
                                border: '1px solid  #ead2ac',
                                fontSize: '15px',
                                fontFamily: ' Arial, Helvetica, sans-serif',
                            }}
                            onChange={(e) => setCurrency(e)}
                            id="sel3"
                        >
                            <Option value="naira" id="naira">
                                {' '}
                                Naira
                            </Option>
                            <Option value="cedis" id="cedis">
                                {' '}
                                Cedis{' '}
                            </Option>
                            <Option value="cad" id="cad">
                                {' '}
                                CAD
                            </Option>
                            <Option value="usd" id="usd">
                                {' '}
                                USD
                            </Option>
                        </Select>
                    </Flexi>

                    <Btn>
                        <Buttonx onClick={sumbitH}>
                            Compute {'  '}
                            <GiComputerFan
                                style={{
                                    fontSize: '15px',
                                    position: 'relative',
                                    top: '3px',
                                }}
                            />{' '}
                        </Buttonx>
                    </Btn>
                </form>
            </Formborder>
        </div>
    )
}

export default Form

import React, { useState } from "react";
import styled from "styled-components";
import { Buttonx, Inputx, Labelx, InputGroupx } from "../UI";
import { commas } from "./Display";

const Mains = styled.div`
  margin: 10px;
`;

const Ans = styled.span`
  margin-left: 20px;
  font-family: ubuntu;
  font-size: 20px;
  font-weight: 500;
`;

function Calc() {
  const [exp, setExp] = useState("");
  const [result, setResult] = useState<number>();
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExp(e.target.value);
  };
  const submitHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const arr = exp.split("");
    let tt = 0;
    if (
      arr.includes("+") ||
      arr.includes("-") ||
      arr.includes("/") ||
      arr.includes("*")
    ) {
      try {
        // eslint-disable-next-line no-eval
        tt = eval(exp);
        setResult(tt);
        setErr(false);
      } catch (e) {
        setErr(true);
        setErrMsg("Error with Input");
      }
    }
  };
  return (
    <Mains>
      <InputGroupx>
        <Labelx> Input Your Expression: </Labelx>
        <Inputx type="text" onChange={changeHandler} value={exp} />
        <Buttonx onClick={submitHandler}> {"=>"} </Buttonx>
        <Ans> {commas(result)}</Ans>
      </InputGroupx>
      {err && (
        <div style={{ color: "red", fontWeight: "bold" }}> {errMsg} </div>
      )}
    </Mains>
  );
}

export default Calc;
